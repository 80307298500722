import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_LOOKUP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_LOOKUP_SUCCESS: {
      const { items } = action.payload;
      const newItems = Object.keys(items).reduce((acc, curr) => {
        acc = [
          ...acc,
          ...items[curr].map((item) => {
            return {
              ...item,
              propertyId: parseInt(curr, 10),
            };
          }),
        ];
        return acc;
      }, []);
      return featureAdapter.setAll(newItems, {
        ...state,
        isLoading: false,
        accommodationsProperties: items,
        error: null,
      });
    }

    case ActionTypes.LOAD_LOOKUP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;
      return featureAdapter.setAll(
        items.map((accommodation) => {
          const { accommodation_child_id, parent_id } = accommodation;
          return {
            ...accommodation,
            childAccommodations: (accommodation_child_id || []).map((id) => {
              return items.find((item) => item.id === id).name;
            }),
            parent_name: parent_id
              ? items.find((item) => item.id === parent_id).name
              : null,
          };
        }),
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_BED_TYPES_BOL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_BED_TYPES_BOL_SUCCESS: {
      const { items } = action.payload;
      return {
        ...state,
        isLoading: true,
        error: null,
        bedTypesBol: items,
      };
    }

    case ActionTypes.LOAD_BED_TYPES_BOL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DELETE_SUCCESS: {
      return featureAdapter.removeOne(action.payload.accommodationId, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.ORDER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.ORDER_SUCCESS: {
      return featureAdapter.map(
        (accommodaion) => {
          const foundOrder = action.payload.accommodationsIds.findIndex(
            (id) => id === accommodaion.id,
          );
          return {
            ...accommodaion,
            order: foundOrder,
          };
        },
        {
          ...state,
          isLoading: false,
        },
      );
    }

    case ActionTypes.ORDER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
