import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IAccommodation } from '../../features/commons/accommodations/models/accommodation.model';
import { AccommodationLookup, BedType } from '../../models';

export const featureAdapter: EntityAdapter<IAccommodation> = createEntityAdapter<
  IAccommodation
>({
  selectId: (model) => model.id,
  sortComparer: (model) => model.order,
});

export interface State extends EntityState<IAccommodation> {
  isLoading?: boolean;
  error?: any;
  accommodationsProperties: AccommodationLookup;
  bedTypesBol: BedType[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  accommodationsProperties: {},
  bedTypesBol: [],
});
