import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { reduce } from 'lodash';

import { IAccommodation } from '../../features/commons/accommodations/models/accommodation.model';
import {
  Accommodation,
  AccommodationLookup,
  AccommodationLookupItem,
  BedType,
} from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getBedTypesBol = (state: State): BedType[] => state.bedTypesBol;

export const getaccommodationsProperties = (
  state: State,
): AccommodationLookup => state.accommodationsProperties;

export const selectAccommodationState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('accommodations');

export const selectAllAccommodationsItems: (state: object) => IAccommodation[] =
  featureAdapter.getSelectors(selectAccommodationState).selectAll;

export const selectAccommodationById = (id: string) =>
  createSelector(
    selectAllAccommodationsItems,
    (accommodations: Accommodation[]) => {
      if (accommodations) {
        return accommodations.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectAccommodationsError: MemoizedSelector<object, any> =
  createSelector(selectAccommodationState, getError);

export const selectAccommodationsIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectAccommodationState, getIsLoading);
export const selectBedTypesBol: MemoizedSelector<object, BedType[]> =
  createSelector(selectAccommodationState, getBedTypesBol);

export const selectAccommodationsProperties: MemoizedSelector<
  object,
  AccommodationLookup
> = createSelector(selectAccommodationState, getaccommodationsProperties);

export const selectAccommodationsWithPropertyId = createSelector(
  selectAccommodationsProperties,
  (accommodations) =>
    reduce(
      accommodations,
      (acc: AccommodationLookupItem[], value, key) => {
        acc = [...acc, ...value.map((val) => ({ ...val, property_id: +key }))];
        return acc;
      },
      [],
    ),
);

export const selectAccommodationsSortedByOrder: MemoizedSelector<
  object,
  Accommodation[]
> = createSelector(
  selectAllAccommodationsItems,
  (accommodations: Accommodation[]) =>
    accommodations.sort((a, b) => a.order - b.order),
);

export const selectAccommodationsPropertiesSortedByOrder: MemoizedSelector<
  object,
  AccommodationLookup
> = createSelector(
  selectAccommodationsProperties,
  (accommodationsProperty: AccommodationLookup) => {
    return Object.keys(accommodationsProperty).reduce((acc, key) => {
      return {
        ...acc,
        [key]: accommodationsProperty[key]
          .slice()
          .sort((a, b) => a.order - b.order),
      };
    }, {});
  },
);

export const selectAccommodationsNames = createSelector(
  selectAllAccommodationsItems,
  (accommodations) => {
    return accommodations.reduce(
      (
        accommodationsNames: {
          [accommodation_id: number]: string;
        },
        accommodation,
      ) => {
        return {
          ...accommodationsNames,
          [accommodation.id]: accommodation.name,
        };
      },
      {},
    );
  },
);
