import { Action } from '@ngrx/store';

import { IAccommodation } from '../../features/commons/accommodations/models/accommodation.model';
import { AccommodationLookup, BedType } from '../../models';

export enum ActionTypes {
  LOAD_LOOKUP_REQUEST = '[Accommodations] Load Lookup Request',
  LOAD_LOOKUP_FAILURE = '[Accommodations] Load Lookup Failure',
  LOAD_LOOKUP_SUCCESS = '[Accommodations] Load Lookup Success',

  LOAD_MULTI_PROPERTY_REQUEST = '[Accommodations] Load Multi Property Request',

  LOAD_REQUEST = '[Accommodations] Load Request',
  LOAD_FAILURE = '[Accommodations] Load Failure',
  LOAD_SUCCESS = '[Accommodations] Load Success',

  LOAD_BED_TYPES_BOL_REQUEST = '[Accommodations] Load Bed Types Bol Request',
  LOAD_BED_TYPES_BOL_FAILURE = '[Accommodations] Load Bed Types Bol Failure',
  LOAD_BED_TYPES_BOL_SUCCESS = '[Accommodations] Load Bed Types Bol Success',

  DELETE_REQUEST = '[Accommodations] Delete Request',
  DELETE_FAILURE = '[Accommodations] Delete Failure',
  DELETE_SUCCESS = '[Accommodations] Delete Success',

  ORDER_REQUEST = '[Accommodations] Order Request',
  ORDER_SUCCESS = '[Accommodations] Order Success',
  ORDER_FAILURE = '[Accommodations] Order Failure',

  RESET_STATE = '[Accommodations] Reset State',
}

export class LoadLookupRequestAction implements Action {
  readonly type = ActionTypes.LOAD_LOOKUP_REQUEST;
  constructor(public payload: { propertyIds: number[] }) {}
}

export class LoadLookupFailureAction implements Action {
  readonly type = ActionTypes.LOAD_LOOKUP_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadLookupSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_LOOKUP_SUCCESS;
  constructor(public payload: { items: AccommodationLookup }) {}
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: IAccommodation[] }) {}
}
export class LoadBedTypesBolRequestAction implements Action {
  readonly type = ActionTypes.LOAD_BED_TYPES_BOL_REQUEST;
}

export class LoadBedTypesBolFailureAction implements Action {
  readonly type = ActionTypes.LOAD_BED_TYPES_BOL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadBedTypesBolSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_BED_TYPES_BOL_SUCCESS;
  constructor(public payload: { items: BedType[] }) {}
}

export class LoadMultiPropertyRequestAction implements Action {
  readonly type = ActionTypes.LOAD_MULTI_PROPERTY_REQUEST;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { accommodationId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { accommodationId: number }) {}
}

export class OrderRequestAction implements Action {
  readonly type = ActionTypes.ORDER_REQUEST;
  constructor(public payload: { accommodationsIds: number[] }) {}
}

export class OrderFailureAction implements Action {
  readonly type = ActionTypes.ORDER_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class OrderSuccessAction implements Action {
  readonly type = ActionTypes.ORDER_SUCCESS;
  constructor(public payload: { accommodationsIds: number[] }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadLookupRequestAction
  | LoadLookupSuccessAction
  | LoadLookupFailureAction
  | LoadBedTypesBolRequestAction
  | LoadBedTypesBolSuccessAction
  | LoadBedTypesBolFailureAction
  | LoadRequestAction
  | LoadMultiPropertyRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | OrderRequestAction
  | OrderFailureAction
  | OrderSuccessAction
  | ResetSuccessAction;
