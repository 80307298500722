import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { AccommodationsLookupResponse, BedType } from '@app/models';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { IAccommodation } from '../../features/commons/accommodations/models/accommodation.model';
import { AccommodationsService } from '../../services';

import * as featureActions from './actions';

@Injectable()
export class AccommodationsStoreEffects {
  constructor(
    private dataService: AccommodationsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.propertyId).pipe(
          map(
            ({ data }: IResponseSuccess<IAccommodation[]>) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  loadBedTypesBolRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadBedTypesBolRequestAction>(
        featureActions.ActionTypes.LOAD_BED_TYPES_BOL_REQUEST,
      ),
      switchMap((action: featureActions.LoadBedTypesBolRequestAction) =>
        this.dataService.loadBedTypesBol().pipe(
          map(
            ({ data }: IResponseSuccess<BedType[]>) =>
              new featureActions.LoadBedTypesBolSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.LoadBedTypesBolFailureAction({ error }),
            );
          }),
        ),
      ),
    ),
  );

  loadMultiPropertyRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadMultiPropertyRequestAction>(
        featureActions.ActionTypes.LOAD_MULTI_PROPERTY_REQUEST,
      ),
      switchMap((action: featureActions.LoadMultiPropertyRequestAction) =>
        this.dataService.loadMultiProperty(action.payload.propertiesIds).pipe(
          map(({ data }: any) => {
            const flattenItems = Object.keys(data || {}).reduce(
              (flat, propertyId) => (flat = [...flat, ...data[propertyId]]),
              [],
            );
            return new featureActions.LoadSuccessAction({
              items: flattenItems,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  loadLookupEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadLookupRequestAction>(
        featureActions.ActionTypes.LOAD_LOOKUP_REQUEST,
      ),
      switchMap((action: featureActions.LoadLookupRequestAction) =>
        this.dataService.loadLookup(action.payload.propertyIds).pipe(
          map(
            ({ data }: AccommodationsLookupResponse) =>
              new featureActions.LoadLookupSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadLookupFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { accommodationId },
        }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(accommodationId).pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('accommodation'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.DeleteSuccessAction({
                accommodationId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  order$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.ORDER_REQUEST),
      switchMap((action: featureActions.OrderRequestAction) => {
        const { accommodationsIds } = action.payload;
        return this.dataService.order(accommodationsIds).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('accommodation'),
              }),
              type: 'success',
            });
            return new featureActions.OrderSuccessAction({ accommodationsIds });
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(new featureActions.OrderFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
